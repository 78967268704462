<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <XForm
        ref="form"
        :model="formModel"
        :rules="formRules"
      >
        <list
          class="ma-b"
          v-for="(item,index) in orderDetail.goodsList"
          :key="index"
        >
          <OrderGoodsItem
            :key="index"
            :data="item"
          />
          <FormItem label="评价">
            <div />
            <Rater
              class="fs-big"
              single-line
              v-model="formModel[index].rating"
            />
          </FormItem>
          <FormItem
            :prop="`${index}:reviewContent`"
            :rules="formRules.reviewContent"
          >
            <XInput
              type="textarea"
              placeholder="请输入...(最多500字)"
              :auto-size="{minRows:2,maxRows:5}"
              v-model="formModel[index].reviewContent"
            />
          </FormItem>
          <FormItem
            :prop="`${index}:reviewAttachmentList`"
            :rules="formRules.reviewAttachmentList"
          >
            <Upload
              :max-files="6"
              v-model="formModel[index].reviewAttachmentList"
              :column="3"
              :action="`/rpc/comm/rs/attachment/uploadFileLibrary?goodsId=${item.goodsId}`"
            />
          </FormItem>
        </list>
      </XForm>
    </container>
    <div class="footer">
      <div
        class="btn btn-main"
        @click="onSubmit"
      >
        提交评价
      </div>
    </div>
  </Page>
</template>

<script>
import { Buyer } from '@/api';
import pageMixin from '@/mixins/layout/page';

export default {
  name: 'ReviewNew',
  mixins: [pageMixin],
  data() {
    return {
      orderDetail: {},
      formModel: {},
      formRules: {
        // orderDetailId: 0,
        // rating: 5,
        reviewContent: {
          validator: this.checkReviewContent,
        },
        reviewAttachmentList: {
          validator: this.checkReviewAttachmentList,
        },
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    const { orderId } = to.params;
    Buyer.Ex.Order.orderDetail({ orderId }).then(json => {
      const res = json.data.data;
      next(vm => {
        vm.orderDetail = res;
        const formModel = {};
        res.goodsList.forEach((item, index) => {
          formModel[index] = {
            orderDetailId: item.id,
            rating: 5,
            reviewContent: '',
            reviewAttachment: '',
            reviewAttachmentList: [],
          };
        });
        vm.formModel = formModel;
      });
    });
  },
  methods: {
    checkReviewContent(rule, value, callback) {
      if (!value) {
        callback(new Error('评价内容不能为空'));
      } else if (value.length > 500) {
        callback(new Error('最多只能填写500字'));
      } else {
        callback();
      }
    },
    checkReviewAttachmentList(rule, value, callback) {
      if (!Array.isArray(value) || !value.length) {
        callback(new Error('评价图片不能为空'));
      } else if (value.length > 6) {
        callback(new Error('最多只能上传10张图片'));
      } else {
        callback();
      }
    },
    onSubmit() {
      console.log(111);
      this.$refs['form'].validate().then(() => {
        const { formModel } = this;
        const data = Object.keys(formModel).reduce((p, c) => {
          const item = {
            ...formModel[c],
          };
          item.reviewAttachment = item.reviewAttachmentList
            .reduce((pp, cc) => (pp.push(cc.name), pp), [])
            .join(',');
          delete item.reviewAttachmentList;
          p.push(item);
          return p;
        }, []);
        console.log(data);
        this.$api.Buyer.Ex.GoodsReview.saveGoodsReview({
          jsonStr: JSON.stringify(data),
        }).then(json => {
          const res = json.data;
          this.$messageBox.tips(res.msg).finally(() => {
            this.$router.go(-1);
          });
        });
      });
    },
  },
};
</script>

<style lang="scss">
</style>

<!-- Created by henian.xu on 2020/1/10. -->

<template>
  <div
    v-if="data&&data.length"
    class="fulfil-give-box"
  >
    <div
      class="out-show"
      @click="onSwitch"
    >
      <div class="label">
        <badge>优惠</badge>
      </div>
      <list class="fulfil-give-list">
        <list-item
          v-for="item in data"
          :key="item.id"
          link
        >
          <div
            class="label"
            slot="label"
          >
            <div class="item-tag">
              {{ typeMap[item.fulfilGiveType] }}
            </div>
            {{ item.activityName }}
          </div>
        </list-item>
      </list>
    </div>

    <!-- popup -->
    <transition name="show">
      <div
        v-show="popupShow"
        class="popup fulfil-give-box-popup"
        ref="popup"
        @click.self="close"
      >
        <transition name="popup-down">
          <div
            v-show="popupShow"
            class="inner"
          >
            <div class="header">
              <div class="label">
                优惠
              </div>
            </div>
            <div class="body">
              <list class="fulfil-give-list">
                <listItem
                  :class="[{'link':item.fulfilGiveType===2}]"
                  v-for="item in data"
                  :key="item.id"
                  @click="onFulfilGiveItem(item)"
                >
                  <div
                    class="label"
                    slot="label"
                  >
                    <div class="item-tag">
                      {{ typeMap[item.fulfilGiveType] }}
                    </div>
                    <template v-if="item.fulfilGiveType===1">
                      <list>
                        <list-item
                          v-for="freeFreigh in item.freeFreightList"
                          :key="freeFreigh.id"
                        >
                          <div
                            class="label"
                            slot="label"
                          >
                            满额 <span class="price">{{ freeFreigh.freeFreightAmount|price }}</span> 免邮: <span class="address">{{ freeFreigh.regionRangeName }}</span>
                          </div>
                        </list-item>
                      </list>
                    </template>
                    <template v-else>
                      {{ item.activityName }}
                    </template>
                  </div>
                </listItem>
              </list>
            </div>
            <div
              class="footer"
            >
              <Button
                label="确定"
                theme="main"
                @click="close"
              />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import popupMixin from '@/mixins/popup';

export default {
  name: 'FulfilGiveBox',
  mixins: [popupMixin],
  data() {
    return {
      freeFreightList: [],
      typeMap: {
        1: '免邮',
        2: '满减',
        3: '满赠',
      },
    };
  },
  props: {
    data: {
      type: Array,
      default: () => ({}),
    },
  },
  methods: {
    onFulfilGiveItem(item) {
      if (item.fulfilGiveType === 2) {
        this.$router.push(`/goods/list/0?fulfilGiveId=${item.id}&footerShow=0`);
        return;
      }
      console.log(item.freeFreightList);
      this.freeFreightList = item.freeFreightList;
      this.open();
    },
  },
};
</script>

<style lang="scss">
.fulfil-give-box {
  > .out-show {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    > .label {
      flex: 0 0 auto;
      padding: $padding 0 $padding $padding;
    }
    > .list {
      flex: 1;
    }
  }
}
.fulfil-give-list {
  > .list-item {
    > .label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > .item-tag {
        flex: 0 0 auto;
        display: inline-block;
        border: 1px solid $color-second;
        color: $color-second;
        padding: 0 $padding-small;
        font-size: 0.85 * 100%;
        border-radius: $padding-small;
        margin-right: $margin-small;
      }
      > .list {
        flex: 1;
        > .list-item {
          padding: 0;
          + .list-item {
            margin-top: $margin;
            border-top: 0;
          }
          > .label {
            white-space: normal;
            word-break: break-all;
          }
        }
      }
    }
  }
}
.popup.fulfil-give-box-popup {
  justify-content: flex-end;
  align-items: stretch;
  > .inner {
    width: 100%;
    background-color: #fff;
    max-height: 80vh;
    > .body {
      padding: 0;
      > .freeFreight-wrap {
        > .item {
          padding: $padding;
          + .item {
            border-top: 1px solid $color-border;
          }
          > .price {
            font-size: 120%;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>

<!-- Created by henian.xu on 2019/2/25. -->

<template>
  <Page>
    <div class="second-header">
      <tabs
        @tabChange="onTabsChange"
        average
      >
        <tab-panel
          label="待评价"
          sign="10"
          :active-panel="reviewStatus==='10'"
        />
        <tab-panel
          label="已评价"
          sign="100"
          :active-panel="reviewStatus==='100'"
        />
      </tabs>
    </div>
    <container ref="container">
      <template v-if="reviewStatus === '10'">
        <order-item
          v-for="item in orderList"
          :key="item.id"
          :data="item"
        />
      </template>
      <template v-if="reviewStatus === '100'">
        <OrderGoodsItem
          class="ma-b nbor-t"
          v-for="item in reviewList"
          :key="item.id"
          :type="2"
          :data="item"
          :to="`/review/detail/${item.id}`"
        />
      </template>

      <infinite-loading
        ref="infinite"
        :disabled="infiniteDisabled"
        @infinite="onInfinite"
      />
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import infiniteLoading from '@/mixins/infiniteLoading';
export default {
  name: 'ReviewList',
  mixins: [pageMixin, infiniteLoading],
  data() {
    return {
      orderList: [],
      reviewList: [],
    };
  },
  computed: {
    reviewStatus() {
      // 10:待评价; 100:已评价
      return this.$route.params.reviewStatus || '10';
    },
  },
  methods: {
    getListData({ currentPage, pageSize } = {}) {
      if (this.reviewStatus === '10') {
        return this.$api.Buyer.Ex.Order.orderList({
          reviewStatus: 10,
          orderStatus: 100,
          currentPage:
            currentPage || this.pagination.currentPage
              ? this.pagination.currentPage + 1
              : 1,
          pageSize,
        }).then(json => {
          const res = json.data;
          this.orderList = this.orderList.concat(res.data);
          return Promise.resolve(json);
        });
      } else if (this.reviewStatus === '100') {
        return this.$api.Buyer.Ex.GoodsReview.buyerGoodsReviewList({
          currentPage:
            currentPage || this.pagination.currentPage
              ? this.pagination.currentPage + 1
              : 1,
          pageSize,
        }).then(json => {
          const res = json.data;
          this.reviewList = this.reviewList.concat(res.data);
          return Promise.resolve(json);
        });
      }
    },
    onTabsChange(item) {
      this.$router.replace({
        path: `/review/list/${item.sign}`,
        query: {
          ...this.$router.query,
        },
      });
    },
  },
  activated() {
    if (this.reviewStatus !== '10') return;
    this.infiniteDisabled = true;
    this.$nextTick(() => {
      this.infiniteDisabled = false;
      this.pagination = {};
      this.orderList = [];
    });
  },
};
</script>

<style lang="scss">
</style>

<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <template v-if="!goodsIds">
      <query-box
        class="second-header"
        top=".89rem"
        placeholder="请输入商品名称"
        show-search-btn
        v-model="keywords"
        @query="onSearch"
      />
      <CatPicker
        v-model="catId"
        class="second-header"
        expand-label-type
        :activity-type="+activityType"
        :fulfil-give-id="fulfilGiveId"
        @input="onCategoryInput"
        @nothing="onCategoryInput"
      />
    </template>
    <container
      class="category"
      ref="container"
    >
      <!--<GoodsCategory
                v-model="catId"
                :activity-type="+activityType"
                @input="onCategoryInput"
                @nothing="onCategoryInput"/>-->
      <div class="pa-as">
        <Waterfall
          class="goods-list-test2"
          :watch="goodsList"
          @reflowed="onReflowed"
        >
          <WaterfallSlot
            v-for="(item,index) in goodsList"
            :key="item.id"
            :order="index"
          >
            <goods-item
              :group-buy="+activityType === 20"
              :data="item"
              waterfall
            />
          </WaterfallSlot>
        </Waterfall>
      </div>
      <!--      <list class="goods">
        <goods-item
          v-for="(item) in goodsList"
          :key="item.id"
          :group-buy="+activityType === 20"
          :data="item"
          double-row
        />
      </list>-->
      <infinite-loading
        ref="infinite"
        :disabled="infiniteDisabled || (!catId && !goodsIds)"
        @infinite="onInfinite"
      />
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import infiniteLoading, { waterfallMixin } from '@/mixins/infiniteLoading';
import { mapGetters } from 'vuex';

export default {
  name: 'GoodsList',
  mixins: [pageMixin, infiniteLoading, waterfallMixin],
  data() {
    return {
      keywords: '',
      goodsList: [],
      cancelSources: [],
      catId: +this.$route.params.catId || 0,
      activityType: this.$route.params.activityType,
    };
  },
  computed: {
    ...mapGetters('goods', ['catMap']),
    catName() {
      const catMap = this.catMap[this.activityType] || {};
      const mapItem = catMap[this.catId];
      return mapItem ? mapItem.name : '';
    },
    goodsIds() {
      let ids = this.$route.query.ids;
      if (!ids) return null;
      return ids.split(',');
    },
    fulfilGiveId() {
      return this.$route.query.fulfilGiveId;
    },
  },
  methods: {
    async rawGetListData(params) {
      const _cancelSource = this.$http.createCancelToken();
      this.cancelSources.push(_cancelSource);
      const { activityType, fulfilGiveId, catId } = this;
      const res = await this.$api.Buyer.Gd.Goods.goodsList({
        activityType,
        fulfilGiveId,
        catId,
        ...params,
      });
      this.goodsList = this.goodsList.concat(res.data.data);
      return res;
    },
    async virtualGetListData(params) {
      const { activityType, fulfilGiveId, goodsIds, catId } = this;
      const currentPage = params.currentPage || 1;
      const pageSize = params.pageSize || 20;
      const {
        data: { data },
      } = await this.$api.Buyer.Gd.Goods.goodsList({
        activityType,
        fulfilGiveId,
        ids: [...goodsIds]
          .splice(Math.max(currentPage - 1, 0) * pageSize, pageSize)
          .join(','),
        catId,
        ...params,
      });
      const res = {
        data: {
          data: data,
          pagination: {
            currentPage,
            pageSize,
            totalRows: goodsIds.length,
            totalPages: ~~(goodsIds && goodsIds.length
              ? goodsIds.length / pageSize || 0
              : 0),
          },
        },
      };
      this.goodsList = this.goodsList.concat(res.data.data);
      return res;
    },
    async getListData({ currentPage, pageSize } = {}) {
      this.cancelSources.forEach(item => {
        item.cancel('取消之前的');
      });
      this.cancelSources = [];
      const { goodsIds } = this;
      let json = null;
      const params = {
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
        pageSize,
      };
      if (!goodsIds) json = await this.rawGetListData(params);
      else json = await this.virtualGetListData(params);
      const res = json.data;
      if (res.pagination && res.pagination.currentPage === 1) {
        this.setShareData({
          title: this.catName,
          // desc: goods.goodsSubtitle,
          // imgUrl: goods.mainImageUrl,
          link: this.$route.fullPath,
          successData: {
            urllinkType: 2,
            objectId: this.catId,
          },
        });
      }
      return Promise.resolve(json);
    },
    onCategoryInput(/*val*/) {
      // if (!val) return;
      this.infiniteDisabled = true;
      this.cancelSources.forEach(item => {
        item.cancel('取消之前的');
      });
      this.cancelSources = [];
      this.goodsList = [];
      this.pagination = {};
      setTimeout(() => {
        this.$nextTick(() => {
          this.infiniteDisabled = false;
        });
      }, 500);

      this.setShareData({
        title: `点击进入【${
          this.catName
        }】查看具体商品详情！好货好价等您来哦！`,
        desc: this.$globalVar.shopData.name,
        link: `/goods/list/${this.catId}`,
        successData: {
          urllinkType: 2,
          objectId: this.catId,
        },
      });
    },
    onSearch() {
      this.$router.push(`/goods/search/${encodeURIComponent(this.keywords)}`);
    },
  },
};
</script>

<style lang="scss">
.goods-list-test {
  //column-count: 2;
  //column-gap: 0;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0.25em;
  grid-auto-flow: row dense;
  grid-auto-rows: minmax(50px, auto);
  //grid-auto-rows: 20px;

  > .item {
    //break-inside: avoid;
    border: 1px solid #f00;
    width: 100%;
    background: #222;
    color: #ddd;

    &:nth-child(2n) {
      height: 10em;
      grid-row: 1 / 3;
      grid-column: 2;
      background-color: #f00;
    }
  }
}
</style>

<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <canvas
      class="canvas"
      v-show="false"
      ref="graphicCanvas"
      width="1000"
      height="1350"
    />
    <container ref="container">
      <template v-if="inited">
        <div class="goods-swiper">
          <ad-swiper
            is-images-background
            :data="imgList"
            :delay="0"
            :loop="false"
          />
          <div
            v-if="+goods.goodsStatus!==3 || goods.buyLimitType === 1"
            class="sold-out"
          />
        </div>

        <div class="goods-info">
          <div
            v-if="isPresellGoods"
            class="header"
          >
            <!--预售-->
            <div class="label">
              预售商品
            </div>
            <div class="countdown-wrap">
              <Countdown
                v-model="countdown"
                :start-timestamps="goods.beginPresellTime"
                :end-timestamps="goods.presellEndTime"
              />
            <!--<div class="tc-red fs-small">预计 {{ goods.estimateShippingTime | moment('YYYY年MM月DD日') }} 24点前发货</div>-->
            </div>
          </div>
          <div class="body">
            <div class="price-wrap">
              <span>
                <div class="price fs-more">
                  <span
                    v-if="isActivityGoods"
                    class="fw-bold"
                  >{{ activityGoods.price | price }}</span>
                  <span
                    v-else
                    class="fw-bold"
                  >{{ goods.price | price }}</span>
                  <span
                    v-if="goods.marketPrice"
                    class="old"
                  >{{ goods.marketPrice | price }}</span>
                  <span
                    v-if="goods.RMBPrice"
                    class="fs-small tc-g6"
                  >&nbsp;≈<span class="price-rmb">{{ goods.RMBPrice | price }}</span></span>
                </div>
                <div class="fs-small tc-g6">进口税: 商品已包含</div>
              </span>
              <div
                to="/distributor/cardList"
                v-if="$hasPower(['onlyDistributorBuy:use','distributionIncomeUpgradeMsg:use'],{'&&':true}) && !(2 & userData.type)"
                class="rise-tips"
              >
                升级权益查看分销价
              </div>
            </div>
            <div
              class="share"
              :class="isCollect && 'active'"
              @click="onCollect"
            >
              <i
                class="f-icon"
                v-if="isCollect"
              >&#xf03b;</i>
              <i
                class="f-icon"
                v-else
              >&#xf03a;</i>
              <div class="label">
                {{ isCollect?'取消':'' }}收藏
              </div>
            </div>
            <div
              class="share active"
              v-if="!!shoeSize"
            >
              <i class="f-icon">&#xf073;</i>
              <div class="label">
                推荐 <span class="badge badge-main">{{ shoeSize }}</span> 码
              </div>
            </div>
          </div>
          <div class="footer">
            <div class="label-wrap">
              <div class="name">
                <span
                  class="tag"
                  v-if="goods.goodsSendMode===2"
                >海外直邮</span>
                <span
                  class="tag"
                  v-else-if="goods.goodsSendMode===4"
                >跨境进口</span>
                <span
                  class="tag"
                  v-else-if="goods.goodsSendMode===5"
                >一般贸易</span>
                <span class="fw-bold">{{ goods.name }}</span>
              </div>
              <div
                class="desc"
                v-if="goods.desc"
              >
                {{ goods.desc }}
              </div>
            </div>
            <div
              v-if="goodsSales"
              class="stock tc-g6"
            >
              销量：{{ goodsSales }}
            </div>
          </div>
          <div
            v-if="goods.discountPrice"
            class="footer npa-t"
          >
            <div class="fs-more">
              <span class="fs-little fw-bold">到手价: </span><span class="price">{{ goods.discountPrice | price }}</span>
            </div>
          </div>
          <div
            v-if="$hasPower(['goods:showSupplierName:use'])"
            class="footer tc-g6"
          >
            入驻商家: {{ goods.supplierName }}
          </div>
        </div>

        <!-- 分享赚/团队提成 -->
        <template v-if="isShareEarn">
          <!--<div class="pa-a">
                    <div
                        v-if="isDistributor && !!($globalVar.shopData.distributionIncomeMode&4)"
                        class="share-earn"
                    >
                        <div class="inner">
                            &lt;!&ndash;<div>团队收益:&nbsp;</div>&ndash;&gt;
                            <div class="commission-list">
                                <div
                                    v-for="(item,index) in goods.eachLevelPriceList"
                                    :key="index"
                                    class="dp-b"
                                >
                                    {{ item.levelName }}:
                                    <span class="price">{{ item.levelIncomePrice }}</span>
                                </div>
                                <Linker
                                    v-if="!goods.eachLevelPriceList || !goods.eachLevelPriceList.length"
                                    to="/distributor/cardList"
                                >
                                    升级权益赚取佣金
                                </Linker>
                            </div>
                        </div>
                        <div class="pa-a">
                            <div
                                class="btn btn-red big radius"
                                @click="onShareEarn"
                            >
                                分享素材
                            </div>
                        </div>
                    </div>
                </div>-->
          <!--<list>
                    <list-item
                        icon="&#xf02a;"
                        icon-class-name="tc-blue"
                        label="分享素材"
                        class="link"
                        @click="onShareEarn"
                    />
                </list>-->
          <div
            ref="copyRecommend"
            v-if="goods.commisionPrice"
            class="share-income"
            :data-clipboard-text="sharedData.link"
          >
            <div class="inner">
              <div class="body">
                <div class="info">
                  <div class="label">
                    预计收益
                  </div>
                  <div class="value">
                    <span class="price">{{ goods.commisionPrice }}</span>
                    <span>收益比例: {{ goods.commisionRate }}%</span>
                  </div>
                </div>
                <div
                  class="share-btn-group"
                  @click="onShareBtn"
                >
                  <!--                  <div class="share-btn">
                    立即<br>分享
                  </div>-->
                  <div
                    class="share-btn"
                    @click.stop.prevent="onShareImgBtn"
                  >
                    图片<br>分享
                  </div>
                </div>
              </div>
              <div class="footer">
                分享好友下单，享受返现
              </div>
            </div>
          </div>
        </template>

        <!--<div
                v-if="isDistributor && ((!!($globalVar.shopData.distributionIncomeMode&4) || goods.commisionPrice))"
                class="pa-a">
                <div class="share-earn">
                    <div class="inner">
                        <div class="commission-list">
                            <div v-if="goods.commisionPrice">推荐收益：<span class="price">{{ goods.commisionPrice | price }}</span></div>
                            <template v-if="isDistributor && !!($globalVar.shopData.distributionIncomeMode&4)">
                                <div
                                    v-for="(item,index) in goods.eachLevelPriceList"
                                    :key="index"
                                    class="dp-b badge badge-red">
                                    {{ item.levelName }}:
                                    <span class="price tc-w">{{ item.levelIncomePrice }}</span>
                                </div>
                                <Linker
                                    v-if="!goods.eachLevelPriceList || !goods.eachLevelPriceList.length"
                                    to="/distributor/cardList">升级权益赚取佣金
                                </Linker>
                            </template>
                        </div>
                        &lt;!&ndash;<div class="desc">分享素材</div>&ndash;&gt;
                    </div>
                    <div
                        class="btn btn-red radius"
                        @click="onShareEarn">分享素材
                    </div>
                </div>
            </div>-->

        <!-- 拼团 -->
        <List
          v-if="isActivityGoods && activityType===20"
          class="ma-t"
        >
          <template v-if="activityGoodsDetail.groupBuyList.length">
            <ListItem class="bor-b">
              <div
                class="label"
                slot="label"
              >
                主人，有 <span class="tc-red">{{ activityGoodsDetail.groupBuyList.length }}</span> 个团在等您参加哦。
              </div>
              <div
                class="extra fs-small tc-g6"
                slot="extra"
                @click="onMoreGroup"
              >
                查看更多 <i class="f-icon">&#xf012;</i>
              </div>
            </ListItem>
            <GroupItem
              v-for="item in activityGoodsDetail.groupBuyList"
              :key="item.id"
              :goods="goods"
              :data="item"
            />
          </template>
          <template v-else>
            <ListItem class="bor-b">
              <div
                class="label"
                slot="label"
              >
                主人，大家在等您开团呢！
              </div>
              <div
                v-if="isAllowOrder"
                class="extra btn btn-main"
                slot="extra"
                @click="onOpenGroup"
              >
                去开团
              </div>
            </ListItem>
          </template>
        </List>

        <list
          v-if="isLogin"
          class="ma-t"
        >
          <list-item
            class="reverse npa-t npa-b"
            extra="送至"
          >
            <div
              class="label lh-normal"
              slot="label"
            >
              <address-picker
                class="nma-a"
                v-model="address"
                is-simple
              />
            </div>
          </list-item>
          <list-item
            v-if="address.id"
          >
            <div
              class="label"
              slot="label"
            >
              运费 <span
                class="pa-l"
                :class="isNaN(freight) ?'tc-red': 'price'"
              >
                <template
                  v-if="isNaN(freight)"
                >{{ freight }}</template>
                <template
                  v-else
                >{{ freight | price }}</template>
              </span>
            </div>
          </list-item>
        </list>
        <FulfilGiveBox
          class="ma-t"
          :data="fulfilGiveList"
        />

        <tabs
          class="average ma-t"
          @tabChange="onTabChange"
        >
          <tab-panel
            label="商品详情"
            :sign="1"
          >
            <Widget
              v-if="goods.goodsSendMode === 1"
              :id="19"
              no-show-complete
            />
            <Widget
              v-else-if="goods.goodsSendMode === 2"
              :id="21"
              no-show-complete
            />
            <Widget
              v-else-if="goods.goodsSendMode === 4"
              :id="16"
              no-show-complete
            />
            <div
              class="pa-a bc-w"
              style="line-height: 0;"
            >
              <img
                v-for="(item,index) in goods.detailImageList"
                :key="index"
                v-lazy="$options.filters.imgCropping(item)"
                width="100%"
              >
            </div>
            <Widget
              v-if="goods.goodsSendMode === 1"
              :id="20"
              no-show-complete
            />
            <Widget
              v-else-if="goods.goodsSendMode === 2"
              :id="22"
              no-show-complete
            />
            <Widget
              v-else-if="goods.goodsSendMode === 4"
              :id="17"
              no-show-complete
            />
          </tab-panel>
          <!--<tab-panel
                    label="评价"
                    :sign="4"
                >
                    <div
                        class="pa-a ta-c tc-g6"
                        v-if="!reviewList"
                    >
                        加载中...
                    </div>
                    <div
                        class="pa-a ta-c tc-g6"
                        v-else-if="!reviewList.length"
                    >
                        <img
                            class="img-obj"
                            style="opacity: .3;"
                            src="~@/assets/images/icon/icon01.png"
                            width="40%"
                        >
                        <div class="tc-g6">
                            暂无评价
                        </div>
                    </div>
                    <template v-else>
                        <ReviewItem
                            v-for="item in reviewList"
                            :key="item.id"
                            :data="item"
                        />
                        <div
                            v-if="reviewListTotalRows>5"
                            class="pa-a"
                        >
                            <Button
                                :to="`/goods/review/${goodsId}`"
                                class="w-100per"
                            >
                                查看更多
                            </Button>
                        </div>
                    </template>
                </tab-panel>-->
          <tab-panel
            v-if="freightTemplateChargeDesc"
            label="配送说明"
            :sign="2"
          >
            <div
              v-if="+currentSign === 2"
              class="pa-a bc-w"
            >
              <Widget
                :json="freightTemplateChargeDesc"
                no-show-complete
              />
            </div>
          </tab-panel>
          <tab-panel
            v-if="aftersaleJson"
            label="售后理赔"
            :sign="3"
          >
            <div
              v-if="+currentSign === 3"
              class="pa-a bc-w"
            >
              <Widget
                :json="aftersaleJson"
                no-show-complete
              />
            </div>
          </tab-panel>
        </tabs>
      </template>
    </container>
    <div
      v-if="isPresellGoods"
      class="second-footer"
    >
      预计 {{ goods.estimateShippingTime | moment('YYYY年MM月DD日') }} 24点前发货
    </div>
    <div class="footer">
      <linker
        :to="isDistributor?`/distributor/home`:`/`"
        class="btn cart"
      >
        <div>
          <i class="f-icon fs-more">&#xf089;</i>
          <div class="fs-small">
            首页
          </div>
        </div>
      </linker>
      <linker
        v-if="$hasPower(['cart:use'])"
        to="/cart"
        class="btn cart"
      >
        <div>
          <em v-if="cartCount">{{ cartCount }}</em>
          <i class="f-icon fs-more">&#xf08b;</i>
          <div class="fs-small">
            购物车
          </div>
        </div>
      </linker>
      <div
        class="btn cart"
        :data-clipboard-text="sharedData.link"
        @click="onShareMethodBtn"
      >
        <div>
          <i class="f-icon fs-more">&#xf02a;</i>
          <div class="fs-small">
            分享
          </div>
        </div>
      </div>

      <template v-if="$globalVar.meiQiaEntId">
        <div
          v-if="meiQiaAllSet"
          class="btn cart"
          @click="onService"
        >
          <div>
            <i class="f-icon fs-more">&#xf024;</i>
            <em v-if="unreadMsgCount">{{ unreadMsgCount }}</em>
            <div class="fs-small">
              客服
            </div>
          </div>
        </div>
      </template>
      <div
        v-else
        class="btn cart"
        @click="onService"
      >
        <div>
          <i class="f-icon fs-more">&#xf024;</i>
          <div class="fs-small">
            客服
          </div>
        </div>
      </div>
      <template v-if="isActivityGoods && activityType===20">
        <BuyPopup
          class="btn btn-second"
          :disabled="!isCanBuy"
          :tips-msg="goods.buyLimitMsg"
          :data="goods"
        >
          <div>
            {{ goods.price }}
            <br>
            单独购买
          </div>
        </BuyPopup>
        <BuyPopup
          ref="groupBuyPopup"
          class="btn btn-main"
          :disabled="!isAllowOrder"
          order-type="20"
          :data="activityGoods"
        >
          发起 {{ activityGoodsDetail.groupBuyNums }} 拼团
        </BuyPopup>
      </template>
      <template v-else-if="isActivityGoods && activityType===10">
        <div class="item">
          <BuyPopup
            class="btn buy"
            :disabled="!isCanBuy"
            :tips-msg="goods.buyLimitMsg"
            order-type="10"
            :data="activityGoods"
          />
        </div>
      </template>
      <template v-else>
        <div
          v-if="!isPresellGoods && $hasPower(['cart:use'])"
          class="item"
        >
          <BuyPopup
            class="btn cart"
            type="cart"
            :disabled="!isCanBuy"
            :tips-msg="goods.buyLimitMsg"
            :data="goods"
          />
        </div>
        <div class="item">
          <BuyPopup
            class="btn buy"
            :disabled="!isCanBuy"
            :tips-msg="goods.buyLimitMsg"
            :data="goods"
          />
        </div>
      </template>
    </div>

    <!-- 弹框 -->
    <XDialog ref="allGroupDialog">
      <div
        class="header tc-red"
        slot="header"
      >
        <div class="label">
          正在拼团
        </div>
      </div>
      <div
        class="body npa-a"
        slot="body"
      >
        <List>
          <GroupItem
            v-for="item in allGroupBuyList"
            :key="item.id"
            :goods="goods"
            :data="item"
          />
        </List>
      </div>
    </XDialog>
    <!--分享方法-->
    <transition name="bounce">
      <div
        class="share-popup down"
        v-show="shareMethodPopupShow"
        @click.self="shareMethodPopupShow=false"
      >
        <List class="inner">
          <ListItem
            ref="copyRecommend2"
            icon="&#xf081;"
            icon-class-name="tc-main"
            label="分享链接"
            :data-clipboard-text="sharedData.link"
            @click="onShareBtn"
          />
          <ListItem
            label="分享图文"
            icon-class-name="tc-main"
            icon="&#xf083;"
            @click="onShareImgBtn"
          />
        </List>
      </div>
    </transition>
    <!--微信分享-->
    <transition name="bounce">
      <div
        class="share-popup"
        v-show="sharePopupShow"
        @click="sharePopupShow=false"
        :style="sharePopupStyle"
      />
    </transition>
    <!--图文二维码弹框-->
    <transition name="bounce">
      <div
        class="share-popup"
        v-show="graphicPopupShow"
        @click.self="graphicPopupShow=false"
      >
        <img
          ref="graphicImg"
          :src="graphicSrc"
          width="80%"
          style="border-radius: .15rem;"
        >
        <div class="tc-w ma-t ta-c fs-big">
          长按保存图片
        </div>
      </div>
    </transition>
  </Page>
</template>

<script>
import QRCode from 'qrcode';
import pageMixin from '@/mixins/layout/page';
import { Buyer } from '@/api';
import shareImg from '@/assets/images/share/share-earn.png';
import shareImg2 from '@/assets/images/share/share-earn2.png';
import Clipboard from 'clipboard';
import meiQiaMixin from '@/mixins/meiQiaMixin';
import MeiQiaControl from '@/store/meiQia/MeiQiaControl';
import FulfilGiveBox from './_FulfilGiveBox';
import { mapGetters } from 'vuex';

export default {
  name: 'GoodsDetail',
  components: { FulfilGiveBox },
  mixins: [pageMixin, meiQiaMixin],
  data() {
    return {
      inited: false,
      reviewList: null,
      reviewListTotalRows: 0,
      goods: {},
      address: {},
      sharedData: {},
      freightData: {},
      collectGoods: {},
      allGroupBuyList: [],
      freightTemplateChargeDesc: '',
      aftersaleJson: '',
      currentSign: 1,
      countdown: {},
      shareImg,
      shareImg2,
      shareMethodPopupShow: false,
      sharePopupShow: false,
      graphicPopupShow: false,

      graphicSrc: '',
      QRCodeSrc: '',
    };
  },
  /*beforeRouteEnter(to, from, next) {
    Buyer.Gd.Goods.goodsDetail({ id: to.params.id })
      .then(json => {
        const res = json.data.data;
        const {
          goods,
          freightTemplateChargeDesc,
          aftersaleJson,
          buyerDeliveryAddress: address,
          collectGoods,
        } = res;
        next(vm => {
          vm.goods = goods;
          vm.freightTemplateChargeDesc = freightTemplateChargeDesc;
          vm.aftersaleJson = aftersaleJson;
          vm.collectGoods = collectGoods || {};
          vm.address = address || {};
          vm.setShareData({
            title: goods.goodsName,
            desc: goods.goodsSubtitle,
            imgUrl: goods.mainImageUrl,
            link: to.fullPath,
            successData: {
              urllinkType: 1,
              objectId: goods.id,
            },
          }).then(data => {
            vm.sharedData = data;
          });
          vm.getReviewList();
        });
      })
      .catch(() => {
        next();
      });
  },*/
  computed: {
    ...mapGetters('user', ['userData', 'isLogin']),
    goodsId() {
      return this.$route.params.id;
    },
    isActivityGoods() {
      return this.goods.isActivityGoods;
    },
    activityGoods() {
      const { goods, isActivityGoods } = this;
      const obj = { ...goods };
      if (!isActivityGoods) return {};
      obj.maxPrice = obj.activityMaxPrice;
      obj.minPrice = obj.activityMinPrice;
      obj.price = obj.activityPrice;
      obj.showStock = obj.activityShowStock;
      obj.skuMap = obj.activitySkuMap;
      obj.sku = obj.activitySku;
      return obj;
    },
    activityGoodsDetail() {
      return this.goods.activityGoodsDetail || {};
    },
    activityType() {
      return this.activityGoodsDetail.activityType;
    },
    imgList() {
      const {
        goods: { imgList, goodsVideoUrl },
      } = this;
      if (!goodsVideoUrl && (!imgList || !imgList.length)) return [];
      const videoList = [];
      if (goodsVideoUrl) {
        videoList.push({
          isVideo: true,
          src: goodsVideoUrl,
          cover: (imgList || [])[0],
        });
      }
      const imageList = imgList.map(item => {
        return {
          imgUrl: item,
        };
      });
      return videoList.concat(imageList);
    },
    freight() {
      const { freightData } = this;
      if (!this.address.id) return '';
      const mode = freightData.freightMode;
      const freight = freightData.freightAmount;
      // const freeFreightOrderAmount = this.address.freeFreightOrderAmount;
      switch (mode) {
        case 1:
          return '全场包邮';
        case 2:
        case 3:
          return !+freight ? '免运费' : +freight === -1 ? '无法配送' : freight;
        default:
          return '';
      }
    },
    isCollect() {
      return this.collectGoods.id;
    },
    // 是否预售
    isPresellGoods() {
      return !!this.goods.isPresellGoods;
    },
    // 是否预售中
    isPreselling() {
      return this.isPresellGoods ? +this.countdown.status === 2 : true;
    },
    isAllowOrder() {
      return !!+this.moduleConfig.isAllowOrder && this.isPreselling;
    },
    isCanBuy() {
      return (
        this.isAllowOrder &&
        this.freightData.freightAmount !== -1 &&
        this.goods.buyLimitType === 0
      );
    },
    unreadCount() {
      return this.unreadMsgCount;
    },
    fulfilGiveList() {
      return this.goods.fulfilGiveList || [];
    },
    buyLimitType() {
      return this.goods.buyLimitType || 0;
    },
    buyLimitMsg() {
      return this.goods.buyLimitMsg || '';
    },
    buyLimitData() {
      const { buyLimitType, buyLimitMsg } = this;
      if (!buyLimitType) return [];
      return [
        {
          type: buyLimitType,
          msg: buyLimitMsg,
        },
      ];
    },
    // 销量
    goodsSales() {
      return this.goods.hasOwnProperty('goodsSales')
        ? this.goods.goodsSales + ''
        : '';
    },
    isShareEarn() {
      const { isDistributor, $globalVar, goods } = this;
      return (
        isDistributor &&
        (!!($globalVar.shopData.distributionIncomeMode & 4) ||
          goods.commisionPrice)
      );
    },
    sharePopupStyle() {
      const { isShareEarn, shareImg, shareImg2 } = this;
      //`background: rgba(0, 0, 0, .8) url('${shareImg_}') top center no-repeat; background-size:100% auto;`
      return {
        background: `rgba(0, 0, 0, .8) url('${
          isShareEarn ? shareImg : shareImg2
        }') top center no-repeat`,
        backgroundSize: '100% auto',
      };
    },

    $$graphicCanvas() {
      return this.$refs.graphicCanvas;
    },
    ctx() {
      return this.$$graphicCanvas.getContext('2d');
    },
  },
  watch: {
    address(val) {
      if (val && val.id) {
        this.$nprogress.start();
        this.$api.Buyer.Sp.ShopFreightTemplate.getGoodsFreightInfo({
          goodsId: this.goods.id,
          deliveryAddressId: val.id,
        })
          .then(json => {
            this.freightData = json.data.data;
          })
          .finally(() => {
            this.$nprogress.done();
          });
      }
    },
  },
  methods: {
    getGoodsDetail() {
      this.inited = false;
      Buyer.Gd.Goods.goodsDetail({ id: this.goodsId })
        .then(json => {
          const res = json.data.data;
          const {
            goods,
            freightTemplateChargeDesc,
            aftersaleJson,
            buyerDeliveryAddress: address,
            collectGoods,
          } = res;
          this.goods = goods;
          this.freightTemplateChargeDesc = freightTemplateChargeDesc;
          this.aftersaleJson = aftersaleJson;
          this.collectGoods = collectGoods || {};
          this.address = address || {};
          this.setShareData({
            title: goods.goodsName,
            desc: goods.goodsSubtitle,
            imgUrl: goods.mainImageUrl,
            link: this.$route.fullPath,
            setUuid: this.isShareEarn,
            successData: {
              urllinkType: 1,
              objectId: goods.id,
            },
          }).then(data => {
            this.sharedData = data;
            // this.drawGraphic();
          });
          this.getReviewList();
          this.inited = true;
        })
        .catch(() => {
          this.inited = true;
        });
    },
    getReviewList() {
      this.$api.Buyer.Ex.GoodsReview.goodsReviewList({
        goodsId: this.goods.id,
        currentPage: 1,
        pageSize: 5,
      }).then(json => {
        const res = json.data;
        this.reviewList = res.data;
        this.reviewListTotalRows = res.pagination.totalRows;
      });
    },
    onBuyTip() {
      if (this.goods.buyLimitType !== 0) {
        this.$messageBox.tips(this.goods.buyLimitMsg);
      }
    },
    onCollect() {
      if (!this.isCollect) {
        this.$api.Buyer.Mb.CollectGoods.collectGoods({
          goodsId: this.goods.id,
        }).then(json => {
          const res = json.data;
          this.collectGoods = res.data;
          this.$messageBox.tips(res.msg, '温馨提示');
        });
      } else {
        this.$api.Buyer.Mb.CollectGoods.delCollectGoods({
          ids: this.isCollect,
        }).then(json => {
          const res = json.data;
          this.collectGoods = {};
          this.$messageBox.tips(res.msg, '温馨提示');
        });
      }
    },
    onOpenGroup() {
      this.$refs['groupBuyPopup'].open();
    },
    onMoreGroup() {
      if (this.allGroupBuyList.length) {
        this.$refs['allGroupDialog'].open();
      } else {
        this.$api.Buyer.Ex.GroupBuy.groupBuyList({
          goodsId: this.goodsId,
        }).then(json => {
          const res = json.data.data;
          this.allGroupBuyList = res;
          this.$refs['allGroupDialog'].open();
        });
      }
    },
    onShareEarn() {
      this.$wx.shareLater({
        ...this.sharedData.successData,
        shareUuid: this.sharedData.uuid,
        urllink: this.sharedData.link,
        shareChannel: 3,
      });
      this.$router.push(
        `/distributor/goods/shareEarn/${this.goodsId}?shareUuid=${
          this.sharedData.uuid
        }`,
      );
    },
    onTabChange(item) {
      this.currentSign = item.sign;
    },
    initClipboard() {
      const { copyRecommend, copyRecommend2 } = this.$refs;
      if (!copyRecommend2) {
        setTimeout(() => {
          this.initClipboard();
        }, 500);
        return;
      }
      const copySuccess = () => {
        this.$wx.shareLater({
          ...this.sharedData.successData,
          shareUuid: this.sharedData.uuid,
          urllink: this.sharedData.link,
          shareChannel: 3,
        });
        this.$messageBox.tips('分享链接复制成功', 3000);
      };
      const copyError = () => {
        this.$messageBox.tips('分享链接复制失败', 3000);
      };
      [copyRecommend, copyRecommend2].forEach(item => {
        if (!item) return;
        const $node = new Clipboard(item.$el || item);
        $node.on('success', copySuccess);
        $node.on('error', copyError);
      });
    },
    onShareMethodBtn() {
      this.shareMethodPopupShow = true;
    },
    onShareBtn() {
      this.shareMethodPopupShow = false;
      if (!this.$globalVar.device.isWeiXin) return;
      this.sharePopupShow = true;
    },
    onShareImgBtn() {
      this.shareMethodPopupShow = false;
      this.graphicPopupShow = !this.graphicPopupShow;
      if (this.graphicPopupShow) {
        this.graphicSrc = `/rpc/buyer/gd/goods/shareImage?id=${this.goods.id}`;
      }
    },
    onService() {
      if (!this.$globalVar.userData.id) {
        this.$router.replace('/buyer/login');
        return;
      }
      if (this.meiQiaAllSet) {
        if (
          !this.$globalVar.meiQiaCustomerService ||
          !this.$globalVar.meiQiaCustomerService.length
        ) {
          MeiQiaControl.showPanel();
          return;
        }
        this.$router.push('/feedback/customerService');
      } else {
        // this.$router.push('/feedback/list');
        window.location.href = this.$globalVar.feedbackUrl;
      }
    },
    async buyLimitPopup() {
      const { buyLimitData } = this;
      await buyLimitData.reduce(async (pre, cur) => {
        await pre.then();
        try {
          await this.$messageBox.alert(cur.msg);
        } catch (e) {
          // noop
        }
        this.$router.replace('/index');
      }, Promise.resolve());
    },
    // 分享图片
    drawText(t, x, y, w, h = 99999999, lh = 70) {
      const { ctx } = this;
      const chr = t.split('');
      let temp = '';
      const row = [];

      // ctx.font = '20px Arial';
      // ctx.fillStyle = 'black';
      // ctx.textBaseline = 'middle';

      for (let a = 0; a < chr.length; a++) {
        if (ctx.measureText(temp).width < w) {
          // 保留
        } else {
          if (row.length + 1 >= h) {
            temp += '...';
            break;
          }
          row.push(temp);
          temp = '';
        }
        temp += chr[a];
      }

      row.push(temp);

      for (let b = 0; b < row.length; b++) {
        ctx.fillText(row[b], x, y + b * lh);
        if (b + 1 >= h) return;
      }
    },
    drawQRCode() {
      return new Promise((resolve, reject) => {
        QRCode.toCanvas(
          this.sharedData.link,
          {
            margin: 3,
            scale: 10,
            errorCorrectionLevel: 'H',
          },
          (error, canvas) => {
            if (error) {
              reject(error);
              return;
            }

            const ctx = canvas.getContext('2d');
            this.$utils.Comm.loadImg(this.$globalVar.shopData.logoUrl)
              .then(img => {
                ctx.fillStyle = '#fff';
                ctx.fillRect(
                  canvas.width / 2 - 45,
                  canvas.height / 2 - 45,
                  90,
                  90,
                );
                ctx.drawImage(
                  img,
                  canvas.width / 2 - 40,
                  canvas.height / 2 - 40,
                  80,
                  80,
                );
                this.QRCodeSrc = canvas.toDataURL();
                /*this.QRCodeSrc = this.$utils.Comm.createObjectURL(
                  this.$utils.Comm.dataURLtoBlob(canvas.toDataURL()),
                );*/
                resolve(this.QRCodeSrc);
              })
              .catch(() => {
                this.QRCodeSrc = canvas.toDataURL();
                /*this.QRCodeSrc = this.$utils.Comm.createObjectURL(
                  this.$utils.Comm.dataURLtoBlob(canvas.toDataURL()),
                );*/
                resolve(this.QRCodeSrc);
              });
          },
        );
      });
    },
    async drawGraphic() {
      const {
        isShareEarn,
        graphicSrc,
        $globalVar,
        goods,
        isActivityGoods,
        activityGoods,
        currency,
        userData,
        $defaultAvatar,
      } = this;
      if (graphicSrc) return;
      const { ctx } = this;
      const cvs = ctx.canvas;
      const pa = 40;
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, cvs.width, cvs.height);
      try {
        await this.drawQRCode();
      } catch (e) {
        // noop
      }
      const [goodsImg, qrcodImg, ...imgs] = await this.$utils.Comm.loadImgs([
        $globalVar.isDev
          ? 'https://relcdn.oss-cn-hangzhou.aliyuncs.com/hotont-assets/images/test/goodsImage.jpg'
          : goods.mainImageUrl,
        this.QRCodeSrc,
      ]);

      let imgH = 0;
      /* logo */
      // ctx.drawImage(logoImg, pa, pa, 160, 160);
      /* 大图 */
      imgH = cvs.width * 0.7;
      ctx.drawImage(goodsImg, (cvs.width - imgH) / 2, 160 + pa, imgH, imgH);
      /* 二维码 */
      const qrcodeWidth = 350;
      let qrcodeTop = cvs.height - pa * 2 - qrcodeWidth;
      const textTotalWidth = cvs.width - qrcodeWidth - pa * 3;
      ctx.drawImage(
        qrcodImg,
        cvs.width - 20 - qrcodeWidth,
        qrcodeTop,
        qrcodeWidth,
        qrcodeWidth,
      );
      /* 小图 */
      if (imgs.length) {
        imgs.forEach((item, index) => {
          const w = (cvs.width - 40) / 2 - 10;
          const i = index - 3;
          ctx.drawImage(item, 20 + (w + 20) * i, cvs.width * 0.6 + 140, w, w);
        });
      }

      /* 其它 */
      ctx.font = '26px Helvetica';
      ctx.fillStyle = '#999';
      ctx.textAlign = 'center';
      ctx.fillText(
        '长按识别二维码 查看详情',
        cvs.width - qrcodeWidth / 2 - 20,
        cvs.height - 20 - pa,
      );

      /* 用户信息 */
      if (isShareEarn) {
        let userAvatar = null;
        try {
          userAvatar = await this.$utils.Comm.loadImg(userData.userAvatar);
        } catch (e) {
          userAvatar = await this.$utils.Comm.loadImg($defaultAvatar);
        }

        ctx.font = '36px Helvetica';
        ctx.fillStyle = '#333';
        ctx.textAlign = 'left';
        ctx.save();
        ctx.arc(pa + 80 / 2, qrcodeTop + 20 + 80 / 2, 80 / 2, 0, 2 * Math.PI);
        ctx.clip();
        ctx.drawImage(userAvatar, pa, qrcodeTop + 20, 80, 80);
        ctx.restore();
        // const userName = '12某某某12某某';
        const userName =
          ($globalVar.isRegisterIHealth
            ? userData.userName
            : userData.nickName) || '';
        this.drawText(
          userName,
          pa + 80 + pa / 2,
          qrcodeTop + 20 + 50,
          200,
          1,
          36 * 1.2,
        );
        ctx.fillStyle = '#868e96';
        this.drawText(
          '为您推荐',
          pa * 3 + 80 + Math.min(ctx.measureText(userName).width - 50, 200),
          qrcodeTop + 20 + 50,
          200,
          1,
          36 * 1.2,
        );
        qrcodeTop += 80 + 36;
      } else {
        qrcodeTop += pa / 2;
      }

      /* 商品信息 */
      ctx.font = '36px Helvetica';
      ctx.fillStyle = '#333';
      ctx.textAlign = 'left';
      this.drawText(
        (goods.goodsName || '') + '',
        pa,
        qrcodeTop + pa,
        textTotalWidth,
        2,
        36 * 1.2,
      );
      ctx.font = '32px Helvetica';
      ctx.fillStyle = '#868e96';
      this.drawText(
        (goods.goodsSubtitle || '') + '',
        pa,
        qrcodeTop + pa + 20 + 32 * 1.2 * 2,
        textTotalWidth,
        2,
        32 * 1.2,
      );
      ctx.font = '60px Helvetica';
      ctx.fillStyle = '#f00';
      const priceText = `${currency} ${
        isActivityGoods
          ? activityGoods.goodsPrice
          : goods.minPrice || goods.price
      }`;
      ctx.fillText(priceText, pa, cvs.height - pa - 18);
      const priceRight = pa + ctx.measureText(priceText).width;

      if (goods.marketPrice) {
        ctx.font = '32px Helvetica';
        ctx.fillStyle = '#999';
        const marketPriceText = `${currency} ${goods.marketPrice}`;
        ctx.fillText(
          marketPriceText,
          priceRight + pa / 2,
          cvs.height - pa - 18,
        );

        ctx.beginPath();
        ctx.moveTo(priceRight + pa / 2, cvs.height - pa - 18 - 10);
        ctx.lineTo(
          priceRight + pa / 2 + ctx.measureText(marketPriceText).width,
          cvs.height - pa - 18 - 10,
        );
        ctx.stroke();
      }

      ctx.save();

      this.graphicSrc = cvs.toDataURL();
      /*this.graphicSrc = this.$utils.Comm.createObjectURL(
        this.$utils.Comm.dataURLtoBlob(cvs.toDataURL()),
      );*/
    },
  },
  created() {
    this.getGoodsDetail();
  },
  mounted() {
    this.buyLimitPopup();
    !this.$globalVar.device.isWeiXin && this.initClipboard();
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #fff;
  padding: 0 $padding-small;

  > .cart {
    color: $gray6;
    flex: 0 0 auto;
    width: auto;
    position: relative;
    padding: $padding-small;

    em {
      font-style: normal;
      font-size: 75%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: $color-red;
      color: #fff;
      line-height: 1.5;
      min-width: 1.5em;
      text-align: center;
      border-radius: 100rem;
    }
  }

  > .item {
    line-height: $line-height;
    padding: $padding-small;
    > .cart,
    > .buy {
      border-radius: 100vw;
      height: 100%;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      color: $color-main;
    }

    > .cart {
      background-color: #fff;
      border: 1px solid $color-main;
    }
    > .buy {
      background-color: $color-main;
      color: #fff;
    }
  }
}

.second-footer {
  background-color: mix(#fff, $color-red, 80%);
  line-height: 2;
  color: $color-red;
  text-align: center;
  font-size: 80%;
  min-height: inherit;
}

.share-earn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $padding 0;
  background-color: #ffeff0;
  border: 0.05rem solid $color-red;
  border-radius: 0.1rem;

  + .share-earn {
    margin-top: $margin;
  }

  > .inner {
    flex: 1 1 auto;
    padding: 0 $padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    border-right: 0.05rem dashed $color-red;
    font-weight: bold;

    &,
    .price {
      color: $color-red;
    }

    .commission-list {
      flex: 1 1 1%;

      > .badge {
        + .badge {
          margin-top: $margin-small;
        }
      }

      > .dividing-line {
        border-bottom: 0.05rem dashed $color-red;
        height: 0;
        margin: $margin 0;
      }
    }
  }

  > .btn {
    margin: 0 $margin;
  }
}

.share-income {
  padding: $padding $padding 0 $padding;

  > .inner {
    padding: $padding;
    background-color: #ffeff0;
    border-radius: 0.1rem;

    > .body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: $padding-small;

      > .info {
        > .label {
          color: $color-red;
        }

        > .value {
          > .price {
            color: $color-red;
            font-size: 0.6rem;
            font-weight: bold;
            margin-right: $margin-small;
          }
        }
      }

      > .share-btn-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        > .share-btn {
          width: 1rem;
          height: 1rem;
          background-color: $color-red;
          color: #fff;
          border-radius: 100vw;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          line-height: 1;

          + .share-btn {
            margin-left: $margin;
          }
        }
      }
    }

    > .footer {
      background-color: transparent;
      padding-top: $padding-small;
      border-top: 1px dashed $color-red;
    }
  }
}
.share-popup {
  background-size: 100% auto !important;
}
</style>

<style lang="scss">
.goods-swiper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;

  > .swiper-container {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  > .sold-out {
    position: absolute;
    z-index: 200;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    //background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon04.png') center no-repeat;
    background-size: 70%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:before {
      content: '补货中';
      font-size: 150%;
      padding: $padding ($padding-big * 1.5);
      letter-spacing: 0.2em;
      border-radius: 100vw;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.goods-info {
  padding: $padding 0;
  background-color: #fff;

  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    > .label,
    > .price-wrap,
    > .countdown-wrap {
      min-height: 1rem;
      padding: 0 $padding;
    }

    > .label {
      flex: 0.4 0.4 1%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: $color-main;
      color: #fff;
      /*font-size: 120%;*/
    }

    > .price-wrap {
      flex: 0.54 0.54 1%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      background-color: $color-main;

      .price {
        color: #fff;
        line-height: 1.2;
      }

      > .price {
        font-size: 0.4rem;
      }

      > .old-price {
        color: #fff;
        font-size: 0.22rem;
        line-height: 1.2;
      }
    }

    > .countdown-wrap {
      flex: 1 1 1%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $color-yellow;

      > .countdown {
        font-size: 0.3rem;
        justify-content: center;
        color: $color-red;
      }
    }
  }

  > .body {
    padding: $padding-small $padding;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > .price-wrap {
      flex: 1 1 1%;
    }

    > .share {
      flex: 0 0 auto;
      padding: 0 $padding;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.22rem;
      color: $gray6;

      > .f-icon {
        font-size: 0.6rem;
        line-height: 1;
        margin-bottom: $margin-small;
      }

      > .label {
        white-space: nowrap;
      }

      &.active {
        color: $color-main;
      }
    }
  }

  > .footer {
    padding: $padding-small $padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    > .label-wrap {
      flex: 1 1 auto;

      > .name {
        font-size: 0.32rem;
        > .tag {
          font-size: 0.2rem;
          background-color: $color-main;
          color: #fff;
          padding: 0.05rem 0.1rem;
          border-radius: 0.08rem;
          vertical-align: middle;
          margin-right: $margin-small;
        }
      }

      > .desc {
        color: $gray6;
        line-height: 1.2;
        margin-top: $margin;
      }
    }

    .rise-tips {
      display: inline-block;
      padding: 0 $padding-small;
      margin-left: $margin-small;
      line-height: 1.6;
      border: 1px solid $color-border;
      border-radius: 5px;
      color: $color-text-minor;
    }
  }
}
</style>
